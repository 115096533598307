import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment'
import { GlobalStoreService } from './services/global-store.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Fileprocessing Orchestrator';
  token = this._oidcSecurityService.getAccessToken();

  isAuthenticated: boolean = false;

  constructor(private _oidcSecurityService: OidcSecurityService, private _globalStore: GlobalStoreService){}

  ngOnInit(): void {
    if (this._shouldUseAuthentication())
      this._enableAuthentication();
  }

  login() {
    this._oidcSecurityService.authorize();
  }

  private _enableAuthentication(): void {
    this._oidcSecurityService.checkAuth().subscribe(async ({ isAuthenticated, userData, accessToken, idToken }) => {
      if (!isAuthenticated) {
        this.login();
      }
      else {
        this.isAuthenticated = isAuthenticated;

        // User-/Zugriffsdaten global hinterlegen
        this._globalStore.setGlobalAccountData(userData, accessToken, idToken, isAuthenticated);

        if (userData != null) {
          if (accessToken != null && idToken != null) {
            console.log('accessToken and idToken found');
          }
          else {
            console.log('no accessToken or idToken found');
          }
        }
        else {
          console.log('no userData found');
        }
      }
    });
  }

  private _shouldUseAuthentication(): boolean {
    if (!(environment.authorityUrl && environment.authorityUrl.length > 0))
      return false;
    if (!(environment.realm && environment.realm.length > 0))
      return false;
      if (!(environment.clientId && environment.clientId.length > 0))
      return false;

    return true;
  }
}
