<div id="container">
  <div  id="checkBoxContainer">
    <mat-checkbox
      *ngIf="checkBoxChecks.includes('Transcoding')"
      id="fileTranscodeCheckBox"
      color="primary"
      (change)="submitChecks($event, Transcoding)"
      >File Transcoding</mat-checkbox
    >
    <mat-checkbox
      *ngIf="checkBoxChecks.includes('Thumbnail')"
      id="thumbnailCheckBox"
      color="primary"
      (change)="submitChecks($event, Thumbnail)">Thumbnail</mat-checkbox>
    <mat-checkbox
      *ngIf="checkBoxChecks.includes('Checksum')"
      id="checksumCheckBox"
      color="primary"
      (change)="submitChecks($event, Checksum)"
      >Checksum</mat-checkbox
    >
    <mat-checkbox
      *ngIf="checkBoxChecks.includes('Subtitle')"
      id="subtitleCheckBox"
      color="primary"
      (change)="submitChecks($event, Subtitle)"
      >Subtitle</mat-checkbox
    >
  </div>
  
  <h2 *ngIf="checkBoxChecks.length <= 0">
    No Processes available that can be reprocessed
  </h2>
  <div id="buttonContainer">
    <button id="reprocessConfirm" [mat-dialog-close]= "subProcessType" mat-button *ngIf="checkBoxChecks.length > 0">Reprocess</button>
    <button id="reprocessCancel" [mat-dialog-close]= false mat-button>Cancel</button>
    
  </div>
  
</div>
