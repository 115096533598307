import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { JobQuery, JobResponse, Page, PageRequest, ReprocessRequest, SubProcessType, UnifiedJob } from '../models';
import { JobIdContainerResponse } from '../models/jobcontainers-model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly _url = `${environment.apibase}/${environment.apiVersion}/Jobs`;
  private readonly _triggerUrl = `${environment.apibase}/${environment.apiVersion}/Trigger`;

  constructor(private readonly _http: HttpClient) { }

  // page(request: PageRequest<Job>, query: JobQuery): Observable<Page<Job>> {
  //   // transform request & query into something your server can understand
  //   // (you might want to refactor this into a utility function)
  //   const obj = {
  //     pageNumber: request.page, 
  //     pageSize: request.size,
  //     sortOrder: request.sort?.order,
  //     sortProperty: request.sort?.property,
  //     ...query
  //   }

  //   let params = new HttpParams();

  //   for (const [k, v] of Object.entries(obj)) {
  //     if (v !== undefined)
  //       params = params.append(k, v.toString());
  //   }
  //   // fetch page over HTTP
  //   return this._http.get<Page<Job>>(this._url, {params: params});
  // }

  // all() : Observable<Job[]> {
  //   return this._http.get<Job[]>(`${this._url}`);
  // }

  allEx() : Observable<UnifiedJob[]> {
    return this._http.get<UnifiedJob[]>(`${this._url}/ex`);
  }

  // single(id: string): Observable<Job> {
  //   return this._http.get<Job>(`${this._url}/${id}`);
  // }

  // triggerFileprocessing(filename: string) : Observable<any> {
  //   return this._http.get(`${this._triggerUrl}?filename=${filename}`);
  // }

  getJobs(pageIndex: number = 1, pageSize: number = 10) : Observable<JobResponse> {
    return this._http.get<JobResponse>(`${this._url}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  }

  getJob(id: string) : Observable<UnifiedJob> {
    return this._http.get<UnifiedJob>(`${this._url}/${id}`);
  }

  getJobContainers(): Observable<JobIdContainerResponse>{
    return this._http.get<JobIdContainerResponse>(`${this._url}/jobcontainers`)
  }

  retryJob(filename: string) {
    return this._http.post(`${this._url}/trigger?filename=${filename}`, {});
  }
  
  reprocess(filename: string, subProcessType: SubProcessType) {
    const request : ReprocessRequest = {
      filename: filename,
      types: subProcessType
    };

    return this._http.post(`${this._url}/reprocess`, request);
  }

  killJob(id: string) : Observable<any> {
    return this._http.delete(`${this._url}/${id}`);
  }
}
