export interface IDirectoryListRequest {
    path:          string;
    requestTarget: string;
    targetId:      string;
    uri:           string;
}

export interface IDirectoryListResponse {
    id:            string;
    uri:           string;
    requestTarget: string;
    path:          Path;
    entries:       Entry[];
}

export interface Entry {
    name:          string;
    size:          number;
    creationTime:  Date;
    lastWriteTime: Date;
    directoryInfo: DirectoryInfo;
    type:          FSEntryType;
}

export interface DirectoryInfo {
    name: Name;
    path: Path;
}

export enum Name {
    FileTransferTest = "FileTransferTest",
}

export enum Path {
    ClipsFileTransferTest = "/Clips/FileTransferTest/",
}

export enum FSEntryType {
    Directory = "Directory",
    File = "File",
}