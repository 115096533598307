import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs'
import { environment } from '../../environments/environment';
import { UnifiedJob, JobIdContainerResponse } from '../models'

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private _connection: signalR.HubConnection | null = null;

  private _subJobExReceived$ = new Subject<UnifiedJob>();

  public get subJobExReceived$(): Observable<UnifiedJob> {
    return this._subJobExReceived$.asObservable();
  }

  private _jobExReceived$ = new Subject<UnifiedJob>();

  public get jobExReceived$(): Observable<UnifiedJob> {
    return this._jobExReceived$.asObservable();
  }

  private _jobContainersReceived$ = new Subject<JobIdContainerResponse>();

  public get jobContainersReceived$(): Observable<JobIdContainerResponse> {
    return this._jobContainersReceived$.asObservable();
  }

  constructor() {
      this._initConnection();
  }

  private _initConnection() {
    if (!this._connection) {
      const url = `${environment.wsbase}/${environment.apiVersion}/hubs/jobs`;


      console.log("SignalRService at", url);

      this._connection = new signalR.HubConnectionBuilder().withUrl(url, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      }) // mapping to the chathub as in startup.cs
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      this._connection.on("SendJobEx", msg => this._jobExReceived$.next(msg as UnifiedJob));
      this._connection.on("SendSubJobEx", msg => this._subJobExReceived$.next(msg as UnifiedJob));
      this._connection.on("SendJobContainers", msg => this._jobContainersReceived$.next(msg as JobIdContainerResponse));
    }
  }

  public async start() {
      try {
        if (!this._connection) return;
        
        if (this._connection.state === signalR.HubConnectionState.Connected || 
          this._connection.state === signalR.HubConnectionState.Connecting ||
          this._connection.state === signalR.HubConnectionState.Reconnecting)
          return;
  
        //this._connectionStatus = ConnectionStatusValue.Connecting;
  
        await this._connection.start();
  
        //this._connectionStatus = ConnectionStatusValue.Connected;
        console.log("connected");
      } catch (err) {
        //this._connectionStatus = ConnectionStatusValue.Diconnected;
        console.log(err);
        setTimeout(() => this.start(), 5000);
      } 
    }
}
