import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { JobMaterialModule } from './material-module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';


import { AppComponent } from './app.component';
import { JobDetailsComponent } from './components/job-details/job-details.component';
import { ProgressBarColorUnified } from './components/job-details/progress-bar-color-unified';
import { JobComponent } from './components/job/job.component';
import { ProgressSpinnerDialogComponent } from './components/progress-spinner-dialog/progress-spinner-dialog.component';
import { KillProcessDialogComponent } from './components/kill-process-dialog/kill-process-dialog.component';
import { ReprocessDialogComponent } from './components/reprocess-dialog/reprocess-dialog.component';
import { ErrorDetailsDialogComponent } from './components/job-details/error-details-dialog/error-details-dialog.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { UnauthorizedViewComponent } from './unauthorized-view/unauthorized-view.component';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { LogoutComponent } from './logout/logout.component';
import { HeaderComponent } from './header/header.component';


@NgModule({
    declarations: [
        AppComponent,
        JobDetailsComponent,
        ProgressBarColorUnified,
        JobComponent,
        ProgressSpinnerDialogComponent,
        KillProcessDialogComponent,
        ReprocessDialogComponent,
        ErrorDetailsDialogComponent,
        UnauthorizedViewComponent,
        LogoutComponent,
        HeaderComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        JobMaterialModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatDialogModule,
        MatCheckboxModule,
        AuthConfigModule,
    ],
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true  }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
