<h1 mat-dialog-title>Error Details</h1>
<div mat-dialog-content>
  <h3>
      <div>Reason</div>
        <div>
            {{data.reason}}
        </div>
    </h3>
    <div><strong>Details:</strong></div>
    <div>
        {{data.details}}
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Close</button>
  </div>
