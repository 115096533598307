<div *ngIf="unifiedJob" style="font-size:12px;height:50vh;">
    <div *ngFor="let child of unifiedJob.children; index as i" style="border: 1px solid grey; padding: 5px;">
        <div class="row">
            <div class="col" style="font-weight: bold;">{{child?.name}}</div>
            <div class="col" style="display: flex; flex-direction: column; justify-content: center;">
                <div style="font-size: 8px;">Progress</div>
                <mat-progress-bar [appProgressBarColorUnified]="(child)" mode="determinate" value="child?.progress"></mat-progress-bar>
                <div *ngIf="child.progressType == 'Percent'" style="font-size: 10px;">{{child?.progress}} %</div>
                <div *ngIf="child.progressType != 'Percent'" style="font-size: 10px;">{{child?.progress}} of {{child?.progressSteps}}</div>
            </div>
            <div class="col">
                <div *ngIf="child.details" style="font-size: 8px;">Details</div>
                <div>{{child.details}}</div>
            </div>
            <div class="col">
                <div style="font-size: 8px;">Created</div>
                <div>{{child.creationDate | date:'dd.MM.YY HH:mm:ss'}}</div>
            </div>
            <div class="col">
                <div style="font-size: 8px;">Modified</div>
                <div>{{child.modifiedDate | date:'dd.MM.YY HH:mm:ss'}}</div>
            </div>
            <div class="col">
                <div style="font-size: 8px;">Completed</div>
                <div>{{child.completedDate | date:'dd.MM.YY HH:mm:ss'}}</div>
            </div>
            <div class="col">
                <div style="font-size: 8px;">Duration ms</div>
                <div>{{duration(child)}}</div>
            </div>
            <div class="col">{{child?.reason}}
                <button *ngIf="child?.errorDetails" matTooltip="Show error details"
                    mat-icon-button
                    aria-label="ErrorDetails"
                    (click)="onErrorDetails($event, child)">
                    <mat-icon>error_outline</mat-icon>
                </button>
            </div>
            <div class="col">{{child?.id}}</div>
        </div>
        <div *ngIf="child.children && child.children.length > 0">
            <div *ngFor="let child of child.children; index as i">
                <div  class="row">
                    <div class="col" style="padding-left:25px;">{{child?.name}}</div>
                    <div class="col" style="display: flex; flex-direction: column; justify-content: center;margin-left:-10px;">
                        <div style="font-size: 8px;">Progress</div>
                        <mat-progress-bar [appProgressBarColorUnified]="(child)" mode="determinate" value="child?.progress"></mat-progress-bar>
                        <div *ngIf="child.progressType == 'Percent'" style="font-size: 10px;">{{child?.progress}} %</div>
                        <div *ngIf="child.progressType != 'Percent'" style="font-size: 10px;">{{child?.progress}} of {{child?.progressSteps}}</div>
                    </div>
                    <div class="col">
                        <div *ngIf="child.details" style="font-size: 8px;">Details</div>
                        <div>{{child.details}}</div>
                    </div>
                    <div class="col">
                        <div style="font-size: 8px;">Created</div>
                        <div>{{child.creationDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                    </div>
                    <div class="col">
                        <div style="font-size: 8px;">Modified</div>
                        <div>{{child.modifiedDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                    </div>
                    <div class="col">
                        <div style="font-size: 8px;">Completed</div>
                        <div>{{child.completedDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                    </div>
                    <div class="col">
                        <div style="font-size: 8px;">Duration ms</div>
                        <div>{{duration(child)}}</div>
                    </div>
                    <div class="col">{{child?.reason}}
                        <button *ngIf="child?.errorDetails" matTooltip="Show error details"
                            mat-icon-button
                            aria-label="ErrorDetails"
                            (click)="onErrorDetails($event, child)">
                            <mat-icon>error_outline</mat-icon>
                        </button>
                    </div>
                    <div class="col">{{child?.id}}</div>
                </div>
                <div *ngIf="child.children && child.children.length > 0" >
                    <div *ngFor="let child of child.children; index as i">
                        <div  class="row">                           
                            <div class="col" style="padding-left:35px;">{{child?.name}}</div>
                            <div class="col" style="display: flex; flex-direction: column; justify-content: center;margin-left:-20px;">
                                <div style="font-size: 8px;">Progress</div>
                                <mat-progress-bar [appProgressBarColorUnified]="(child)" mode="determinate" value="child?.progress"></mat-progress-bar>
                                <div *ngIf="child.progressType == 'Percent'" style="font-size: 10px;">{{child?.progress}} %</div>
                                <div *ngIf="child.progressType != 'Percent'" style="font-size: 10px;">{{child?.progress}} of {{child?.progressSteps}}</div>
                            </div>
                            <div class="col">
                                <div *ngIf="child.details" style="font-size: 8px;">Details</div>
                                <div>{{child.details}}</div>
                            </div>
                            <div class="col">
                                <div style="font-size: 8px;">Created</div>
                                <div>{{child.creationDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                            </div>
                            <div class="col">
                                <div style="font-size: 8px;">Modified</div>
                                <div>{{child.modifiedDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                            </div>
                            <div class="col">
                                <div style="font-size: 8px;">Completed</div>
                                <div>{{child.completedDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                            </div>
                            <div class="col">
                                <div style="font-size: 8px;">Duration ms</div>
                                <div>{{duration(child)}}</div>
                            </div>
                            <div class="col">{{child?.reason}}
                                <button *ngIf="child?.errorDetails" matTooltip="Show error details"
                                    mat-icon-button
                                    aria-label="ErrorDetails"
                                    (click)="onErrorDetails($event, child)">
                                    <mat-icon>error_outline</mat-icon>
                                </button>
                            </div>
                            <div class="col">{{child?.id}}</div>
                        </div>
                        <div *ngIf="child.children && child.children.length > 0" >
                            <div *ngFor="let child of child.children; index as i">
                                <div  class="row">
                                   
                                    <div class="col" style="padding-left:45px;">{{child?.name}}</div>
                                    <div class="col" style="display: flex; flex-direction: column; justify-content: center;margin-left:-30px;">
                                        <div style="font-size: 8px;">Progress</div>
                                        <mat-progress-bar [appProgressBarColorUnified]="(child)" mode="determinate" value="child?.progress"></mat-progress-bar>
                                        <div *ngIf="child.progressType == 'Percent'" style="font-size: 10px;">{{child?.progress}} %</div>
                                        <div *ngIf="child.progressType != 'Percent'" style="font-size: 10px;">{{child?.progress}} of {{child?.progressSteps}}</div>
                                    </div>
                                    <div class="col">
                                        <div *ngIf="child.details" style="font-size: 8px;">Details</div>
                                        <div>{{child.details}}</div>
                                    </div>
                                    <div class="col">
                                        <div style="font-size: 8px;">Created</div>
                                        <div>{{child.creationDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                                    </div>
                                    <div class="col">
                                        <div style="font-size: 8px;">Modified</div>
                                        <div>{{child.modifiedDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                                    </div>
                                    <div class="col">
                                        <div style="font-size: 8px;">Completed</div>
                                        <div>{{child.completedDate | date:'dd.MM.YY HH:mm:ss'}}</div>
                                    </div>
                                    <div class="col">
                                        <div style="font-size: 8px;">Duration ms</div>
                                        <div>{{duration(child)}}</div>
                                    </div>
                                    <div class="col">{{child?.reason}}
                                        <button *ngIf="child?.errorDetails" matTooltip="Show error details"
                                            mat-icon-button
                                            aria-label="ErrorDetails"
                                            (click)="onErrorDetails($event, child)">
                                            <mat-icon>error_outline</mat-icon>
                                        </button>
                                    </div>
                                    <div class="col">{{child?.id}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

