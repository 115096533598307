import { environment } from './../../environments/environment';
import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';


@NgModule({
    imports: [AuthModule.forRoot({
        config: {
              authority: environment.authorityUrl + '/realms/' + environment.realm,
              redirectUrl: window.location.origin,
              postLogoutRedirectUri: window.location.origin,
              clientId: environment.clientId,
              scope: 'openid profile offline_access config-store-api email file-processing-orchestrator roles web-origins', // 'openid profile offline_access ' + your scopes
              responseType: 'code',
              silentRenew: true,
              useRefreshToken: true,
              renewTimeBeforeTokenExpiresInSeconds: 30,
              unauthorizedRoute: '/unauthorized',
              secureRoutes: [environment.apibase],
              ignoreNonceAfterRefresh: true
          }
      })],
    exports: [AuthModule],
})
export class AuthConfigModule {}
