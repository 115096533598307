import { HeaderComponent } from './header/header.component';
import { JobComponent } from './components/job/job.component';
import { LogoutComponent } from './logout/logout.component';
import { UnauthorizedViewComponent } from './unauthorized-view/unauthorized-view.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';

const routes: Routes = [
  {
    path:'unauthorized', component: UnauthorizedViewComponent
  },
  {
    path: 'logout', component: LogoutComponent
  },
    {
      path: 'job', component: JobComponent,
      canActivate: [AutoLoginAllRoutesGuard]
    },
    {
      path: '', pathMatch: 'full', redirectTo: 'job'
    }
    // {path: 'test', component: HeaderComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
