import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { ProgressType, UnifiedJob } from 'src/app/models'

@Directive({
selector: '[appProgressBarColorUnified]'
})
export class ProgressBarColorUnified implements OnChanges {
    static counter = 0;

    @Input() appProgressBarColorUnified!: UnifiedJob;

    color: string ='green';

    styleEl:HTMLStyleElement = document.createElement('style');

    //generate unique attribule which we will use to minimise the scope of our dynamic style 
    uniqueAttr = `app-progress-bar-color-${ProgressBarColorUnified.counter++}`;

    constructor(private el: ElementRef) { 
    const nativeEl: HTMLElement = this.el.nativeElement;
    nativeEl.setAttribute(this.uniqueAttr,'');
    nativeEl.appendChild(this.styleEl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.appProgressBarColorUnified?.progressType == ProgressType.Percent)
            this.updateColor();
        else
            this.updateColorSteps();
    }


    updateColor(): void {
        let c: string = 'green';
        if (this.appProgressBarColorUnified.reason)
            c = 'red';
        
        else if (this.appProgressBarColorUnified.progress == 100){
            c = 'green';
        } 
        else if (this.appProgressBarColorUnified.progress > 50){
            c = 'gold';
        }
        else if (this.appProgressBarColorUnified.progress > 25){
            c = 'orange';
        }
        else{
            c = 'yellow';
        }

        this.color = c;

    // update dynamic style with the uniqueAttr
        this.styleEl.innerText = `
        [${this.uniqueAttr}] .mat-progress-bar-fill::after {
            background-color: ${this.color};
        }`;

        this.styleEl.innerText = `
        [${this.uniqueAttr}] .mat-progress-bar-element::after {
            background-color: ${this.color};
        }`;

        this.styleEl.innerText = `
        [${this.uniqueAttr}] .mat-progress-bar-element {
            background-color: ${this.color};
        }`;
    }

    updateColorSteps(): void {
        let c: string = 'green';

        if (this.appProgressBarColorUnified.reason)
            c = 'red';
        else if (this.appProgressBarColorUnified.progress === this.appProgressBarColorUnified.progressSteps){
            c = 'green';
        } 
        else {
            c = 'gold';
        }

        this.color = c;

    // update dynamic style with the uniqueAttr
        this.styleEl.innerText = `
        [${this.uniqueAttr}] .mat-progress-bar-fill::after {
            background-color: ${this.color};
        }`;

        this.styleEl.innerText = `
        [${this.uniqueAttr}] .mat-progress-bar-element::after {
            background-color: ${this.color};
        }`;

        this.styleEl.innerText = `
        [${this.uniqueAttr}] .mat-progress-bar-element {
            background-color: ${this.color};
        }`;
    }
}