<app-header *ngIf="noHeader===0"></app-header>
<div class="scrollable">
  <mat-selection-list
    #jobList
    [(ngModel)]="selectedJobs"
    (ngModelChange)="onJobChange($event)"
    [multiple]="false"
  >
    <mat-list-option
      *ngFor="let job of jobs"
      [value]="job.id"
      style="padding: 0.5em"
    >
      <div class="job-entry">
        <div class="col">
          <div>Id</div>
          <div>{{ job.id }}</div>
        </div>
        <div class="col">
          <div>Filename</div>
          <div>{{ job.filename }}</div>
        </div>
        <div class="col">
          <div>State</div>
          <div [ngClass]="getJobStateClass(job)">
            <span *ngIf="isRunning(job)">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </span>

            <!-- <span><mat-spinner *ngIf="isRunning(job)" diameter="15"></mat-spinner></span> -->
            {{ job.state }}
          </div>
        </div>
        <div class="col">
          <div>Created</div>
          <div>{{ job.creationDate | date: "dd.MM.YY HH:mm:ss Z" }}</div>
        </div>
        <div class="col">
          <div>Completed</div>
          <div>{{ job.completedDate | date: "dd.MM.YY HH:mm:ss Z" }}</div>
        </div>
        <div style="max-width: 10em" class="col">
          <div>Error Reason</div>
          <div
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
            matTooltipClass="matTooltip"
            matTooltip="{{ job.reason }}"
          >
            {{ job.reason }}
          </div>
        </div>
        <div class="col">
          <div class="row">
            <button
              matTooltip="Retry"
              mat-icon-button
              aria-label="Retry"
              (click)="onRetry($event, job)"
            >
              <mat-icon>replay</mat-icon>
            </button>
            <button
              matTooltip="Kill"
              mat-icon-button
              aria-label="Kill"
              (click)="onKill($event, job)"
            >
              <mat-icon>remove_circle</mat-icon>
            </button>
            <button
            matTooltip="Reprocess"
            mat-icon-button
            aria-label="Reprocess"
            (click)="onReprocess($event, job)">
            <mat-icon>history</mat-icon>
          </button>
          </div>
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>
</div>
>
<div id="PaginatorLevel">
  <div id="jobContainerMetrics">
    <span>Processor Count: {{jobIdContainerResponse.processorCount}}</span>
    <span>Max Concurrent Jobs: {{jobIdContainerResponse.maxConcurrentJobs}}</span>
    <span>Running Jobs: {{jobIdContainerResponse.runningJobCount}}</span>
    <!-- <span>Queued Jobs:  -->
    <div style="margin-left: 36em;margin-top: -3.5em;display: flex; row-gap: 20px;">
      <div>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title class="queue-font">
              Queue
            </mat-panel-title>
            <mat-panel-description class="queue-font">
              {{jobIdContainerResponse.jobContainers.length}} Jobs queued
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="queue-font">Priority/Filename</p>
          <div *ngFor="let j of jobIdContainerResponse.jobContainers" class="queue-font">
            <span>Priority: {{j.priority}}</span>
            <span>File: {{j.filename}}</span>
          </div>
        </mat-expansion-panel>
      </div>
      <div style="margin-left: 30px;">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title class="queue-font">
              Running Job Queue
            </mat-panel-title>
            <mat-panel-description class="queue-font">
              {{jobIdContainerResponse.runningContainers.length}} Jobs running
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p class="queue-font">Priority/Filename/Created</p>
          <div *ngFor="let j of jobIdContainerResponse.runningContainers" class="queue-font">
            <span>Priority: {{j.priority}}</span>
            <span>File: {{j.filename}}</span>
            <span>Created: {{j.created}}</span>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
  <mat-paginator
    [length]="jobCount"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="getPaginatorData($event)"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
<div *ngIf="selectedNode" class="scrollableDetail">
  <app-job-details [unifiedJob]="selectedNode"></app-job-details>
</div>
