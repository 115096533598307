import { UnifiedJob } from './../../models/unified-job';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox'
import { SubProcessType } from 'src/app/models'

@Component({
  selector: 'app-reprocess-dialog',
  templateUrl: './reprocess-dialog.component.html',
  styleUrls: ['./reprocess-dialog.component.scss'],
})
export class ReprocessDialogComponent implements OnInit {
  checkBoxChecks: string[] = [];
  subProcessType: SubProcessType = SubProcessType.None;

  get Checksum(): SubProcessType {
    return SubProcessType.Checksum;
  }

  get Thumbnail(): SubProcessType {
    return SubProcessType.Thumbnail;
  }

  get Transcoding(): SubProcessType {
    return SubProcessType.Transcoding;
  }

  get Subtitle(): SubProcessType {
    return SubProcessType.Subtitle;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public job: UnifiedJob) {}

  ngOnInit(): void {
    this._onOpen();
  }

  private _onOpen() {
    this.checkBoxChecks = [];

    const processChild: UnifiedJob | undefined = this.job.children.find(j => j.name === 'Process');

    if (!processChild) {
      console.error("No process child found");

      return;
    }

    if (processChild.children.some((e) => e.name === 'Transcoding')) {
      this.checkBoxChecks.push('Transcoding');
    }
    if (processChild.children.some((e) => e.name === 'Thumbnail')) {
      this.checkBoxChecks.push('Thumbnail')
    }
    if (processChild.children.some((e) => e.name === 'Checksum')) {
      this.checkBoxChecks.push('Checksum')
    }
    if (processChild.children.some((e) => e.name === 'Subtitle')) {
      this.checkBoxChecks.push('Subtitle')
    }
  }

  submitChecks($event: MatCheckboxChange, subProcessType: SubProcessType) {
    if ($event.checked)
      this.subProcessType |= subProcessType;
    else
      this.subProcessType ^= subProcessType;
  }
  

}
