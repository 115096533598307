import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'
import { UnifiedJob } from 'src/app/models'
import { SignalRService } from 'src/app/services/signal-r.service'
import * as moment from 'moment';
import { ErrorDetailData, ErrorDetailsDialogComponent } from './error-details-dialog/error-details-dialog.component';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnDestroy {
  @Input() unifiedJob!: UnifiedJob;

  private readonly _destroy$: Subject<void> = new Subject<void>();

  constructor(private readonly _signalRService: SignalRService, private _cdr: ChangeDetectorRef, public dialog: MatDialog) { }

  /*
  this._router.events.pipe(filter((event) => event instanceof NavigationEnd), takeUntil(this._destroy$))
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects.includes('profile')) {
          this.showEditProfile = true;
        } else this.showEditProfile = false;
      });
      */
  ngOnInit(): void {
    // this._signalRService.subJobExReceived$.pipe(filter(msg => msg.jobId === this.unifiedJob?.jobId), takeUntil(this._destroy$)).subscribe(msg => {
    //   console.log("JobDetailsComponent:SubJob", msg);
      
    //   this._addOrUpdateSubJob(msg, this.unifiedJob);
    // });

    this._signalRService.subJobExReceived$.pipe(takeUntil(this._destroy$)).subscribe(msg => {
      console.log("JobDetailsComponent:UnifiedJob", this.unifiedJob);
      console.log("JobDetailsComponent:SubJob", msg);

      if (msg.name && msg.name.startsWith('Process'))
        console.log("Processing");
      
      if (this._addOrUpdateSubJob(msg, this.unifiedJob))
        this._cdr.detectChanges();
    });


    this._signalRService.jobExReceived$.pipe(filter(msg => msg.jobId === this.unifiedJob?.jobId),takeUntil(this._destroy$)).subscribe(msg => {
      console.log("JobDetailsComponent:Job", msg);

      if (this.unifiedJob.modifiedDate < msg.modifiedDate && this._hasToUpdate(msg)) {
        this.unifiedJob = msg;

        this._cdr.detectChanges();
      }
    });
  }

  private _hasToUpdate(job: UnifiedJob) : boolean {
    const newCount = this._countChildren(job);
    const oldCount = this._countChildren(this.unifiedJob);

    return newCount >= oldCount;
  }

  private _countChildren(job: UnifiedJob): number {
    if (!job.children)
      return 0;
    
    let count = 0;
    for (let i = 0; i < job.children.length; ++i)
      count += this._countChildren(job.children[i]);
      
    return count;
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  duration(node: UnifiedJob) : number | null {
    if (!node.completedDate) return null;

    const completed = moment(node.completedDate); 
    const creation = moment(node.creationDate);
    const time = completed.diff(creation);

    return time;
  }

  trackSubJob(index: number, subJob: UnifiedJob) {
    return subJob;
  }

  onErrorDetails(ev: UIEvent, subJob: UnifiedJob) {
    const data : ErrorDetailData = {
      reason: subJob.reason,
      details: subJob.errorDetails
    };
    this.dialog.open(ErrorDetailsDialogComponent, {data});
  }

  private  _addOrUpdateSubJob(subJob: UnifiedJob, parent: UnifiedJob): boolean {
    if (parent.id === subJob?.parentId) {
      if (!parent.children) {
        parent.children = [];

        parent.children.push(subJob);

        return true;
      }

      for (let idx = 0; idx < parent.children.length; ++idx) {
        if (parent.children[idx].id === subJob.id) {
          parent.children[idx] = subJob;
          return true;
        }
      }

      parent.children.push(subJob);

      parent.children.sort((a, b) => {
        if (a.creationDate < b.creationDate)
          return -1;
        if (a.creationDate > b.creationDate)
          return 1;

          return 0;
      });

      return true;
    }

    if (!parent.children || parent.children.length === 0)
      return false;

    for (let idx = 0; idx < parent.children.length; ++idx) {
      if (parent.children[idx].id === subJob.id) {
        parent.children[idx] = subJob;
        return true;
      }
    }

    for (let idx = 0; idx < parent.children.length; ++idx) {
      if (this._addOrUpdateSubJob(subJob, parent.children[idx]))
        return true;
    }

    if (subJob?.jobId == parent.id) {
      if (!parent.children || parent.children.length === 0) {
        parent.children = [subJob];

        return true;
      }

      const idx = parent.children.findIndex((element, index, array) => element.id === subJob.id);

      if (idx != -1)
        parent.children[idx] = subJob;
      else
        parent.children.push(subJob);

      parent.children.sort((a, b) => {
        if (a.creationDate < b.creationDate)
          return -1;
        if (a.creationDate > b.creationDate)
          return 1;

          return 0;
      });

      return true;
    }

    return false;
  }

}
