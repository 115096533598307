import { Injectable } from '@angular/core';
import { AccountData } from '../models/accountData'

@Injectable({
  providedIn: 'root'
})
export class GlobalStoreService {
  public globalAccountData!: AccountData; // Logindaten vom User hinterlegen

  constructor() { }

  public setGlobalAccountData(data: any,accessToken: string,idToken: string,isAuthenticated: boolean) {
    
    this.globalAccountData = {
      userData: data,
      accessToken: accessToken,
      idToken: idToken,
      isAuthenticated: isAuthenticated
    };
  }
}
