/**
 * Flags to describe sub process types
 */
export enum SubProcessType {
    None = 0x00,
    Checksum = 0x01,
    Thumbnail = 0x02,
    Transcoding = 0x04,
    Subtitle = 0x08,
    All = 0x0f
}

export interface ReprocessRequest {
    filename: string;
    types: SubProcessType;
}
