export enum UnifiedJobType {
    Job = 'Job', 
    SubJob = 'SubJob'
}

export enum JobState {
    Created = 'Created',
    Running = 'Running',
    Completed = 'Completed',
    Faulted = 'Faulted'
}

export enum ProgressType {
    Percent = 'Percent', 
    Step = 'Step'
}

export interface JobResponse {
    data:       UnifiedJob[];
    pagination: Pagination;
}

export interface UnifiedJob {
    type:          UnifiedJobType;
    id:            string;
    state:         JobState;
    progress:      number;
    progressType:  ProgressType;
    progressSteps: number;
    filename:     string;
    creationDate:  Date;
    modifiedDate:  Date;
    completedDate?: Date;
    children:      UnifiedJob[];
    jobId?:        string;
    parentId?:     string;
    name?:         string;
    details?:      string;
    reason?:       string;
    errorDetails?: string;
}

export interface Pagination {
    pageCount:       number;
    totalItemCount:  number;
    pageNumber:      number;
    pageSize:        number;
    hasPreviousPage: boolean;
    hasNextPage:     boolean;
    isFirstPage:     boolean;
    isLastPage:      boolean;
    firstItemOnPage: number;
    lastItemOnPage:  number;
}
