import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerImg = '../../assets/img/HeaderImage.svg'
  uiVersion = environment.uiVersion
  name = ''


  constructor(private oidcSecurityService: OidcSecurityService) { }

  ngOnInit(): void {
    this.oidcSecurityService.getUserData().subscribe((userdata)=>{
      const userData = userdata
      this.name = userData.name
    })

   

  }

  onCopyToken(){
    this.oidcSecurityService.getAccessToken().subscribe((token)=>{
      navigator.clipboard.writeText(token)
    })
  }

  logout(){
    this.oidcSecurityService.logoff()
  }



}
